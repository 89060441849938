.bes-container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1366px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}

.bes-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    font-size: 5.25rem;
    font-weight: bold;
    color: #d9d9d9;
}

.bes-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row nowrap;
}

.bes-logos {
    flex: 2;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 600px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.logo-node {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10rem;
}

.logo-mongodb {
    width: 100%;;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10rem;
}

.logo-oracle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10rem;
}

.logo-nest {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10rem;
}

.bes-list {
    width: 100%;
    height: 100%;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 2.75rem;
    padding: 4rem 0rem 0rem 16rem;
}

.best-list-subtitle {
    text-align: left;
    font-size: 2.75rem;
    color: #5e17eb;
    font-weight: bold;
    letter-spacing: -1px;
}

.best-list-description {
    text-align: left;
    margin-left: 2rem;
    font-size: 1.75rem;
    color: white;
    font-weight: 500;
}

@media (max-width: 900px) {

    .bes-container {
        row-gap: 1rem;
    }

    .bes-content {
        flex-direction: column-reverse;
    }

    .bes-logos {
        flex: 1;   
        flex-direction: row;
    }

    .logo-node, .logo-mongodb, .logo-oracle, .logo-nest {
        padding: 0;
        flex: 1;
        justify-content: center;
    }

    .bes-list {
        flex: 4;
        padding: 0 8rem;
        row-gap: 1.5rem;
    }

    .best-list-subtitle {
        font-size: 2rem;
    }

    .best-list-description {
        font-size: 1.5rem;
    }
}

@media (max-width: 440px) {
    .bes-container {
        row-gap: 0.75rem;
    }

    .bes-title {
        padding: 1rem 1rem 0 1rem;
        font-size: 2.75rem;
    }

    .bes-content {
        justify-content: flex-end;
        align-items: flex-start;
        row-gap: 0.75rem;
    }

    .bes-logos {
        flex: 0;
        align-items: flex-start;
    }

    .bes-list {
        flex: 0;
        padding: 0 3.5rem 0 2rem;
    }
}