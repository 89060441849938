.macbookpro-mockup {
    position: relative;
    width: 700px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.macbookpro-mockup-img {
    position: relative;
    width: 700px;
    height: 100%;
}

.macbookpro-mockup img {
    width: 100%;
    height: 100%;
    display: block;
}

.macbookpro-inside-img {
    z-index: -1;
    position: absolute;
    width: 604px;
    top: 12px;
    left: 48px;
    height: auto;
    overflow: hidden;
}

.macbookpro-inside-img img {
    padding-bottom: 10rem;
    width: 100%;
    height: auto;
    position: relative;
}

@media (max-width: 900px) {

    .macbookpro-mockup-img {
        width: 305px;
    }

    .macbookpro-inside-img {
        top: 6px;
        width: 264px;
        left: 218px;
    }
}