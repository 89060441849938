.resume-container {
  background: transparent;
}

.resume-button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.resume-button img {
    width: 45px;
    height: 45px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 325px;
    height: 150px;
    text-align: center;
    row-gap: 1rem;
  }

  .modal h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  .modal p {
    font-size: 1.5rem;
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
  }

  .modal-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }

  .modal-buttons button:hover {
    background: #BD7643;
  }
  