.slide-container {
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-top);
    overflow: hidden;
    background-color: #5799b8;
}

.slide {
    padding-top: 65px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slide.frontend {
    background-color: #5799b8;
}

.slide.backend {
    background-color: #64b07e;
}

.slide.contact {
    background-color: #203162;
}

.slide.portfolio {
    transition: background-color 1s ease;
}