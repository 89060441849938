.logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px;
}

.logo-box img {
    width: 100%;
    height: 100%;
}