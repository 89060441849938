.contact{
    width: 100%;
    height: 100%;
}

.contact-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1366px;
    column-gap: 12rem;
}

.contact-info {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15rem;
}

.info-logo {
    width: 150px;
    height: 150px;
}

.info-logo img {
    width: 100%;
    height: 100%;
}

.info-data {
    flex: 1;
    padding-top: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info-data-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.info-data-phone, .info-data-email {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.contact-form {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2.5rem;
}

@media (max-width: 900px) {
    .contact-container {
        padding-top: 2rem;
        flex-direction: column;
        row-gap: 2rem;
    }

    .contact-info {
        padding: 0;
    }
}

@media (max-width: 440px) {
    .info-logo {
        width: 100px;
        height: 100px;
    }
}