* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-section{
  text-align: center;
  font-size: 4em;
  color: #fff;
}

.actions{
  list-style-type: none;
}
.actions button{
  padding: .93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  border: 0;
  color: #fff;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
}
.actions button:hover{
  background: #fff;
  color: #35495e;
}
