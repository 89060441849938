.cs_socialMedia {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: end;
    column-gap: 1em;
}

.cs_socialMedia-logo {
    width: 45px;
    height: 45px;
}

.cs_socialMedia-logo img {
    width: 100%;
    height: auto;
}