.dotnav {
    position: fixed;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
}

.dot {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

