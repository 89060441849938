.project-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.project-slide-content {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 4rem;
    row-gap: 2rem;
}

.project-slide-title {
    font-size: 4.5rem;
    font-weight: bold;
    color: #5e17eb;
}

.project-slide-description {
    font-size: 2.5rem;
    color: white;
    text-align: center;
    padding: 0 10rem;
}

.mockup {
    flex: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
}

.mockup a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
}

@media (max-width: 900px) {
    .mockup {
        flex-direction: column-reverse;
        padding-top: 0;
        justify-content: flex-end;
        row-gap: 3rem;
    }

    .project-slide-content {
        flex: 1;
        padding: 1rem 0;
        row-gap: 1rem;
    }
    
    .project-slide-title {
        font-size: 2.5rem;
        font-weight: bold;
    }
    
    .project-slide-description {
        font-size: 1.5rem;
        color: white;
        text-align: center;
        padding: 0 2rem 1rem 2rem;
    }
}