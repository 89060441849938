.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    display: flex;
    z-index: 1000;
    align-items: center;
    background-color: transparent;
    transition: background-color 0.3s;
}

.navbar-logo {
    margin-left: 2rem;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-logo.disable {
    display: none;
}

.navbar-logo img {
    width: 45px;
    height: 45px;
}

.navbar-buttons {
    width: 100%;
    display: flex;
    flex: 1;
    column-gap: 2rem;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
}

.navbar-language-options {
    padding-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #fff;
    font-weight: 600;
    column-gap: 1.5rem;
    cursor: pointer;
}

.navbar-language-options .active {
    color: #BD7643;
    font-weight: bolder;
}

.navbar-button {
    background: none;
    border: none;
    margin: 0 1rem;
    padding: 1rem 0;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 500;
    width: 9rem;
    text-align: center;
}

.navbar-button.active {
    color: #BD7643;
    font-weight: bolder;
}

.navbar-button-sub {
    text-decoration: none;
    position: relative;
}

.navbar-button-sub.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.2rem;
    bottom: -0.5rem;
    color: #BD7643;
    left: 0;
    background-color: currentColor;
}

.navbar-hamburger {
    z-index: 1100;
    cursor: pointer;
    margin-left: 2rem;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: #fff;
    font-weight: bold;
}

.navbar-sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70%;
    max-width: 300px;
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 80px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
}

.navbar-sidebar.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;
    transform: translateX(0);
}

@media (max-width: 900px) {
    .navbar-button.desktop {
        display: none;
    }

    .navbar-hamburger {
        display: flex;
    }

    .navbar-logo {
        display: none;
    }
}
