.portfolio-slider {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 100%;
}

.portfolio-slider-title {
    font-size: 5.5rem;
    font-weight: bold;
    color: #d9d9d9;
    text-align: center;
    padding-top: 4rem;
}

.portfolio-slider-container {
    display: flex;
    width: 300vw;
    height: 100%;
}

.horizontal-slide {
    flex: 0 0 100vw;
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-arrow, .right-arrow {
    border: unset;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    font-size: 5rem;
    font-weight: bolder;
    cursor: pointer;
    background-color: transparent;
    color: white;
}

.left-arrow {
    left: 5rem;
}

.right-arrow {
    right: 5rem;
}

@media (max-width: 900px) {
    .portfolio-slider-title {
        font-size: 3rem;
        padding-top: 0;
    }

    .left-arrow {
        top: 50%;
        left: 4rem;
    }

    .right-arrow {
        top: 50%;
        right: 4rem;
    }
}

