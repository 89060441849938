.iphone-mockup {
    position: relative;
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.iphone-mockup-img {
    position: relative;
    width: 150px;
    height: 100%;
}

.iphone-mockup img {
    width: 100%;
    height: 100%;
    display: block;
}

.iphone-inside-img {
    z-index: -1;
    position: absolute;
    width: 130px;
    top: 8px;
    left: 11px;
    height: auto;
    overflow: hidden;
}

.iphone-inside-img img {
    padding-bottom: 10rem;
    width: 100%;
    height: auto;
    position: relative;
}

@media (max-width: 900px) {

    .iphone-mockup {
        width: 80px;
    }

    .iphone-mockup-img {
        width: 120px;
    }

    .iphone-inside-img {
        top: 6px;
        width: 72px;
        left: 4px;
    }
}