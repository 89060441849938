.hero {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('../../../assets/home-background.png');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-header{
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: end;
    justify-content: center;
    column-gap: 4rem;
}

.hero-header-logo{
    width: 150px;
    height: 150px;
}

.hero-header-logo img{
    width: 100%;
    height: 100%;
}

.hero-header-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    margin-bottom: 2rem;
}

.title {
    font-size: 5.25rem;
    font-weight: bold;
    color: #d9d9d9;
}

.subtitle {
    font-size: 3rem;
    font-weight: 700;
    color: white;
    padding: 0 2rem;
}

.hero-description{
    width: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 1.75rem;
    margin-left: 190px;
}

@media (max-width: 900px) {
    .hero-header {
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    .hero-description {
        margin-left: 0;
        text-align: center;
        width: 100%;
        padding: 0 3rem;
        font-size: 1.5rem;
    }
}

@media (max-width: 440px) {
    .hero-header-logo {
        width: 100px;
        height: 100px;
    }

    .title {
        padding-top: 1rem;
        font-size: 3.5rem;
    }

    .subtitle {
        font-size: 2.5rem;
        padding: 0 4rem;
    }
}